@font-face {
  font-family: "Source Han Sans CN";
  src: url(./SourceHanSansCN.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SourceHanSansSC";
  src: url(./SourceHanSansCN.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}