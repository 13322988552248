// 调整iview样式
.ivu-btn-primary{
  background-color:#00c1de;
  border-color:#00c1de;
}
.ivu-btn-primary.active, .ivu-btn-primary:active{
  background-color:#33cde5;
  border-color:#33cde5;
}
.ivu-btn-primary:hover{
  background-color:#33cde5;
  border-color:#33cde5;
}