.photo-solt {
  position: relative;
}

.video-dialog {
  position: relative;
}

.picture-selet {
  .picture-selet__detail {
    display: flex;

    .picture-selet__layout {
      flex: 1;
      width: 685px;
      margin-left: 20px;
      box-sizing: border-box;

      .picture-selet__action {
        position: absolute;
        right: 0px;
        top: 0px;
      }

      .picture-selet__header {
        height: 49px;
        text-align: left;
        line-height: 49px;
        position: relative;

        .el-breadcrumb__inner span {
          font-size: 16px;
          font-weight: 400;
          line-height: 49px;
          color: #000000;
          cursor: pointer;
        }

        .el-breadcrumb__inner:hover {
          color: #343435;
        }

        .el-breadcrumb__item:not(:first-child):last-child .el-breadcrumb__inner span {
          color: #adadad;
        }
      }

      .picture-selet__content {
        position: relative;
        width: 100%;
        height: 486px;
        background: #ffffff;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        text-align: left;
        padding: 16px;
        box-sizing: border-box;

        .picture-selet__tools {
          height: 40px;
          margin-bottom: 16px;
        }

        .picture-selet__content-empty {
          position: relative;
          width: 100%;
          height: 100%;

          div {
            width: 180px;
            margin: 0 auto;
            text-align: center;
            padding-top: 20px;
          }

          .le-icon-default {
            font-size: 180px;
            text-align: center;
            line-height: 100%;
          }
        }

        .picture-selet__action {
          float: left;
          margin-left: 8px;
          line-height: 40px;

          .el-checkbox {
            padding: 0 8px;
          }
        }

        .picture-selet__paging {
          padding: 24px;
          text-align: right;
          position: absolute;
          bottom: 0;
          right: 0;

          ::v-deep .el-pagination__editor .el-input__inner {
            border-color: #dcdfe6 !important;
          }
        }

        ::v-deep .el-popover--plain {
          padding: 15px;
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            display: inline-block;
            width: 124px;
            height: 129px;
            margin-left: 8px;
            margin-bottom: 8px;

            .picture-selet__content-label {
              position: relative;
              width: 100%;
              height: 100%;

              input {
                position: absolute;
                left: -99999px;
              }
            }

            .picture-selet__content-input:checked + .picture-selet__content-item {
              background: rgba(98, 60, 235, 0.05);
              border: 1px solid #343435;
              border-radius: 3px;

              .picture-selet__content-close {
                opacity: 1;
              }
            }

            .picture-selet__content-popover {
              position: absolute;
            }

            .picture-selet__content-item {
              height: 100%;
              width: 100%;
              border: 1px solid rgba(0, 0, 0, 0);
              box-sizing: border-box;
              padding: 10px 20px;
              border-radius: 3px;

              .picture-selet__content-close {
                position: absolute;
                left: 6px;
                top: 6px;
                width: 20px;
                height: 20px;
                line-height: 20px;
                background: #343435;
                border-radius: 50%;
                opacity: 1;
                z-index: 1;
                text-align: center;
                opacity: 0;

                i.le-icon {
                  width: 12px;
                  height: 12px;
                  line-height: 12px;
                  font-size: 12px;
                  color: #ffffff;
                  z-index: 2;
                }
              }

              .el-button--mini {
                padding: 9px 5px;
                width: 100%;
                text-align: center;
                color: #606266;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              // &:hover {
              //     background: rgba(98, 60, 235, 0.05);
              //     border: 1px solid #343435;

              // }
            }

            p {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              line-height: 1em;
              font-size: 12px;
              text-align: center;
            }

            .picture-selet__content-item-photo {
              position: relative;
              width: 84px;
              height: 84px;

              .le-icon {
                font-size: 24px;
                height: 24px;
                width: 24px;
                line-height: 24px;
              }

              .picture-selet__content-item-icon {
                position: absolute;
                width: 26px;
                height: 18px;
                line-height: 16px;
                text-align: center;
                background: #000000;
                opacity: 0.4;
                border-radius: 0px 9px 9px 0px;
                left: 0;
                top: 5px;
                z-index: 2;

                .le-icon {
                  font-size: 16px;
                  height: 18px;
                  width: 18px;
                  line-height: 18px;
                }
              }

              .picture-selet__content-item-image {
                position: relative;
                max-height: 84px;
                overflow: hidden;
                padding-top: 100%;
              }

              .picture-selet__content-item-cover {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: 100% 100%;
              }
            }
          }
        }
      }
    }

    ::v-deep .el-card {
      flex: 0 0 286px;
      width: 286px;

      height: 536px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 4px;

      .el-card__header {
        padding: 13px 20px;
        background: #f3f5f7;
        text-align: left;
        color: rgba(0, 0, 0, 0.45);
        font-weight: 500;
        font-size: 14px;
      }

      .el-card__body {
        padding: 0;

        .el-scrollbar__wrap {
          overflow-x: hidden;
        }
      }

      .picture-selet__detail-group {
        width: 100%;
        overflow: hidden;

        &-add {
          width: 100%;
          height: 40px;
          text-align: center;
        }

        .el-radio-group {
          display: block;
        }

        .el-radio__input {
          position: absolute;
          left: -9999px;
        }

        .el-radio__label {
          padding-left: 0;

          .el-input {
            width: 200px;
          }
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            position: relative;
          }

          .picture-selet__detail-action {
            position: absolute;
            right: 14px;
            top: 0;
            height: 48px;
            line-height: 48px;
            cursor: pointer;
            font-size: 13px;
            color: #343435;
          }

          .picture-selet__detail-radio {
            text-align: left;
            position: relative;
            display: block;
            height: 48px;
            line-height: 48px;
            width: 100%;

            &:hover {
              background: rgba(98, 60, 235, 0.05);
            }

            .el-input {
              width: 250px;
              height: 32px;
              background: #ffffff;
              border: 1px solid #343435;
              border-radius: 4px;

              .el-input-group__append {
                border: 0;
              }

              .el-button {
                padding: 9px 0;
                width: 46px;
                background: #343435;
                border-radius: 0px 4px 4px 0px;
                border: 0;
                border-bottom: 1px solid #343435;
                color: #ffffff;

                span {
                  color: #ffffff;
                }
              }
            }

            padding-left: 54px;

            img {
              position: absolute;
              top: 15px;
              left: 24px;
              width: 18px;
              height: 16px;
            }

            span {
              font-size: 14px;
              font-weight: 400;
              color: #6a6a6a;
            }
          }

          .picture-selet__detail-radio.is-checked {
            background: rgba(98, 60, 235, 0.05);
          }
        }
      }
    }

    .el-card.is-always-shadow {
      box-shadow: none;
    }
  }

  .picture-selet-content {
    position: absolute;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    left: -1px;
    top: -1px;
    border-radius: 4px;
    overflow: hidden;
    display: block;

    &:hover .picture-selet-tips {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 32px;
      line-height: 32px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 4px;
      color: #ffffff;
      font-size: 14px;
    }
  }

  .picture-selet-image {
    position: relative;
    max-height: 500px;
    overflow: hidden;
    padding-top: 38%;
  }

  .picture-selet-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background: #f3f5f7;
    background-size: cover;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.5);
  }
}

.dialog-group {
  &__search {
    width: 368px;
  }

  &__content {
    width: 720px;
    height: 231px;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    margin-top: 16px;
  }

  &__item {
    box-sizing: border-box;
    width: 33.3333%;
    height: 231px;
    padding: 16px;
    float: left;

    ::v-deep .el-scrollbar {
      width: 100%;
      height: 160px;
      margin-top: 16px;

      .el-scrollbar__wrap {
        overflow-x: hidden;
      }

      .el-radio {
        display: block;
        width: 100%;
        height: 32px;
        line-height: 32px;
      }
    }
  }

  &__item + &__item {
    border-left: 1px solid #dcdfe6;
  }

  ::v-deep .el-cascader-panel {
    margin-top: 16px;

    .el-cascader-item {
      width: 238px;
      border-right: solid 1px #e4e7ed;

      &:nth-child(3) {
        border-right: 0;
      }
    }

    .el-input-group {
      width: 208px;
      margin: 16px;
    }

    .el-cascader-menu {
      width: 100%;
      border-right: 0;
    }
  }
}
